import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'


const SEO = ({
  description = '',
  lang = 'en',
  meta = [],
  title
}) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={ title }
    titleTemplate="%s | Greg Venech"
    meta={[
      {
        name: 'description',
        content: description
      },
      {
        property: 'og:title',
        content: title
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'og:type',
        content: 'website'
      }
    ].concat(meta)}
  />
)

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO