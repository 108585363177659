import React from 'react'
import { SocialIcon } from 'react-social-icons'
import './social.css'

const block = 'social'

/**
 * Displays a social icon link based on the given `url`
 *
 * @see https://github.com/jaketrent/react-social-icons
 * @todo submit a pr to add stack overflow (already been discussed)
 * @return {object} - React markup
 */
const Social = props => (
	<SocialIcon
		{ ...props }
		className={ block }
		style={{
			width: 38,
			height: 38
		}}
	/>
)

export default Social